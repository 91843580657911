<template>
    <t-section v-if="state === 0" v-bind:schemaName="schemaName" v-bind:isCustomIconPanel="true" v-bind:isCustomRow="isCustomRow" v-bind:defaultSort="defaultSort" v-bind:isHideButton="isHideButton" v-bind:fields="fields" v-bind:headers="headers">
        <template v-slot:icon-panel>
            <div class="text-left pl-3">
                <img src="@/assets/section/table.svg" v-on:click="openGrid" class="icon-size m-2 d-inline">
                <img v-if="!isHideChart" src="@/assets/section/chart.svg" v-on:click="openChart" class="icon-size d-inline">
                <img v-if="!isHideCustom" src="@/assets/section/send.svg" v-on:click="openCustom" class="icon-size d-inline">
            </div>
        </template>
        <template v-slot:buttons="{filters}">
            <slot name="buttons" :filters="filters"></slot>
        </template>
        <template v-slot:count="{ data }">
            <slot name="count" :data="data"></slot>
        </template>
        <template v-slot:body="{ data, headers, getRowValue, router, routerOpen}">
            <slot name="body" :data="data" :headers="headers" :getRowValue="getRowValue" :router="router" :routerOpen="routerOpen"></slot>
        </template>
    </t-section>
    <div v-else-if="state === 1">
        <div class="text-left pl-3">
            <img src="@/assets/section/table.svg" v-on:click="openGrid" class="icon-size m-2 d-inline">
            <img v-if="!isHideChart" src="@/assets/section/chart.svg" v-on:click="openChart" class="icon-size d-inline">
            <img v-if="!isHideCustom" src="@/assets/section/send.svg" v-on:click="openCustom" class="icon-size d-inline">
        </div>
        <slot name="analytics" :schemaName="schemaName">
        </slot>
    </div>
    <div v-else>
        <div class="text-left pl-3">
            <img src="@/assets/section/table.svg" v-on:click="openGrid" class="icon-size m-2 d-inline">
            <img v-if="!isHideChart" src="@/assets/section/chart.svg" v-on:click="openChart" class="icon-size d-inline">
            <img v-if="!isHideCustom" src="@/assets/section/send.svg" v-on:click="openCustom" class="icon-size d-inline">
        </div>
        <slot name="custom" :schemaName="schemaName">
        </slot>
    </div>
</template>
<script>
import TSection from '@/components/Sections/Section.vue';
export default {
    name:"SectionV3",
    components:{
        TSection,
    },
    props:{
        headers: Array,
        fields: Array,
        schemaName: { type: String, required: true},
        isCustomRow: { type: Boolean },
        isHideButton: { type: Boolean },
        isHideChart: { type: Boolean},
        isHideCustom: { type: Boolean},
        defaultSort: String
    },
    data() {
        return {
            state:0
        }
    },
    methods:{
        openGrid(){
            this.state = 0;
        },
        openChart(){
            this.state = 1;
        },
        openCustom(){
            this.state = 2;
        }
    }
}
</script>

<style scoped>
.icon-size{
    width: 40px;
    height: 40px;
}
</style>