import rows from '@/mixins/rows.js';
export default {
    mixins:[rows],
    props: { 
        headers: Array,
        fields: Array,
        schemaName: { type:String, required: true},
        defaultSort: String,
    },
    data(){
        return {
            currentSort:"",
            isNotPages: false,
            currentSortType: "asc",
            currentPage: 1,
            sizePage: 15,
            loading: true,
            filter: {
                type: "",
                value: null,
                field: ""
            },
            filterTypes: [
                { name: "Равно", value: "Equal", char:"=", type: "all"},
                { name: "Не равно", value: "NotEqual", char: "<>", type: "all" },                
                { name: "Больше", value: "More", char: ">", type: "number, date" },
                { name: "Меньше", value: "Less", char:"<", type: "number, date" },
                { name: "Содержит", value: "Contains", char:"сорержит", type: "string" },
                { name: "Не содержит", value: "NotContains", char:"не содержит", type: "string" }
            ],
            pagination: {
                entities:[]
            },
            filters:[],
        }
    },
    computed: {
        currentPageSize:{
            get(){
                return this.sizePage * this.currentPage;   
            }
        },
        oldPageSize:{
            get(){
                return this.sizePage * (this.currentPage - 1);
            }
        },
        currentSortDir: {
            get() {
                return this.currentSortType;
            },
            set(value) {
                this.currentSortType = value;
            }
        },
        rowsData: {
            get() {
                return this.rowsSortData
            }
        },        
        rowsSortData: {
            get() {
                return [...this.rowsFilterData].sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'desc') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
        rowsFilterData: {
            get() {
                var data = [...this.pagination.entities];              
                return data;
            }
        }
    },
    watch: {
        filters: {
            handler() {
                this.loadRows();
            },
            deep:true
        }
    },
    methods: {
        nextPage(){
            if (this.pagination.totalPages > this.currentPage - 1)
            {
                this.currentPage += 1;
                this.loadRows();
            }
        },
        backPage(){
            if (this.currentPage > 1) {
                this.currentPage -= 1;
                this.loadRows();
            }
        },
        getFilterString(filter) {
            return this.fields.find(x => x.field == filter.field)?.name
        },
        formatDate(date) {
            var dateField = new Date(date);
            return new Date(dateField.getFullYear(), dateField.getMonth(), dateField.getDate());
        },
        addFilter(filter) {
            this.filters.push(filter);
            localStorage.setItem(this.schemaName, JSON.stringify(this.filters));
            this.filter = {
                type: "",
                value: null,
                field: ""
            };
            this.loadRows();
        },
        removeFilter(indexFilter) {
            this.filters = this.filters.filter((_, index) => index !== indexFilter);
            localStorage.setItem(this.schemaName, JSON.stringify(this.filters));
            this.loadRows();
        },
        clearFilters() {
            this.filters = [];
            localStorage.removeItem(this.schemaName);
        },
        sort(field) {
            if(field === this.currentSort) 
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            this.currentSort = field;
        },
        loadRows() {
            this.loading = true;
            this.$store.dispatch('pagination', { table: this.schemaName, query: { page: this.currentPage, filters: this.filters }})
                .then((response) => {
                    this.pagination = response.data.data;
                    this.loading = false;
            });
        },
    },
    mounted() {
        this.currentSort = this.headers[0].field;
        if(this.defaultSort)
            this.currentSortDir = this.defaultSort;
        var result = localStorage.getItem(this.schemaName);
        if(result)
            this.filters = JSON.parse(result);
    }
}