<template>
    <div class="rounded shadow bg-white m-2 mr-3 flow-enabled">
        <table class="table table-hover rounded shadow">
            <thead>               
                <tr class="rounded shadow">
                    <th></th>
                    <th class="text-center" v-for="header of headers" v-bind:key="header.field">
                        <p class="d-inline">{{ header.name }} &nbsp;</p>
                    </th>
                    <th></th>
                </tr>                
            </thead>
            <tbody v-for="(row, index) of rows" v-bind:key="index">
                <tr>
                    <td v-on:click.prevent="getProducts(row.id)" v-if="products.filter(x => x?.activityId == row.id).length == 0">+</td>
                    <td v-on:click.prevent="removeProducts(row.id)" v-else>-</td>
                    <td class="text-center"
                        v-bind:class="header.class ? header.class : ''"
                        v-for="header of headers" v-bind:key="index+header.field"
                        v-on:click="router(row.id, 'OrderBotPage')">
                        {{ getRowValue(row, header) }}
                    </td>
                    <td><button class="btn btn-sm btn-danger" v-on:click.prevent="cancel(row.number, row.contact.code)">Отменить</button></td>
                </tr>
                <td :colspan="headers.length+1" v-if="products.filter(x => x?.activityId == row.id).length > 0">
                    <table class="col">
                        <thead>               
                            <tr class="rounded shadow">
                                <th class="text-center">
                                    Код продукта
                                </th>
                                <th class="text-center">
                                    Размер
                                </th>
                            </tr>                
                        </thead>
                        <tbody v-for="row of products.filter(x => x?.activityId == row.id)" v-bind:key="row.id">
                            <tr>
                                <td>
                                    {{ row.product?.code }}
                                </td>
                                <td>
                                    {{ row.productRemain?.character?.name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tbody>
        </table>
        <div v-if="rows.length === 0" class="text-center">У вас отсутствую активные резервы</div>
    </div>
</template>

<script>
import rows from '@/mixins/rows.js';
export default {
    name:"Reservations",
    mixins:[rows],
    data(){
        return {
            headers:[{ field:"number", name:"Номер"}, { field:"reservationExpired", name:"Время истечения", isDate:true}],
            rows:[],
            products:[],
        }
    },
    methods:
    {
        loadRows() {
            this.$http.get(window.location.origin + "/api/v1/order/reservations")
                .then((response) => {
                    this.rows =  response.data.data;
            });
        },
        getProducts(rootId){
            this.$store.dispatch('getFilter', { table: "orderproduct", body: { id: rootId, rootColumn: "ActivityId" }})
                .then((response) => {
                    this.products = [...this.products, ...response.data.data];
            });
        },
        removeProducts(rootId){
            this.products = this.products.filter(x => x?.activityId !== rootId);
        },
        cancel(number, contact) {
            this.$store.dispatch('postAPI', { controller: 'order', method: 'cancel/byNumber', body: { number, contact } })
                .then(_ => {
                    this.$toast.success("Резерв снят");
                    this.loadRows();
                }).catch(error => {
                    this.$toast.error(error.response.data.message)
                });
        }
    },
    mounted(){
        this.loadRows();
    }
}
</script>