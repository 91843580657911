<template>
    <t-section v-bind:schemaName="'deliveryConfiguration'" v-bind:fields="fields" v-bind:headers="headers"></t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "DeliverySection",
        components:{
            TSection,
        },
        data() {
            return {
                headers:[
                    { field:"apiKey", name:"Api Key", sort:true, router: { field:"id", name:"DeliveryPage" }},
                    { field:"limit", name:"Лимит суммы покупок", sort:true,  router: { field:"id", name:"DeliveryPage" } },
                    { field:"value", name:"Текущая сумма покупок", sort:true,  router: { field:"id", name:"DeliveryPage" } }, 
                ],
                fields:[],
            }
        }
    }
</script>