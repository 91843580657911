<template>
<div class="flow-enabled">
    <div>
        <div v-if="!isCustomIconPanel" class="text-left"></div>
        <slot v-else name="icon-panel"></slot>
    </div>
    <div class="row">
        <div class="text-left pt-2 pb-2 col-sm-2" v-if="!isHideButton">
            <div class="bg-white rounded shadow">            
                <div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-success" v-on:click="router(uuid, editPage)">{{ $filters.localize("button.add") }}</button>
                    <button v-if="this.filters.length > 0" type="button" class="btn btn-sm btn-outline-info" v-on:click="clearFilters">Очистить фильтр</button>
                </div>
            </div>
        </div>    
        <slot name="buttons" class="col-sm-1" :filters="filters"></slot>
    </div>
    <form class="input-group  pr-4" v-if="this.fields.length > 0" v-on:submit.prevent="addFilter(filter)">
        <select class="form-control" v-model="filter.field">
            <option v-for="option in fields" v-bind:key="option.field" :value="option.field">
                {{ option.name }}
            </option>
        </select>
        <select class="form-control" v-model="filter.type">
            <option v-for="option in typesFilterfilter(filter)" v-bind:key="option.value" :value="option.value">
                {{ option.name }}
            </option>
        </select>
        <input v-if="currentInputType(filter) != 'lookup'" :type="currentInputType(filter)" class="form-control text-center" v-model="filter.value" required>
        <select v-else class="form-control" v-model="filter.value" required>
            <option v-for="option in fields.find(x => x.field == filter.field).lookup" v-bind:key="option.id" :value="option.id">
                {{ option.name }}
            </option>
        </select>         
        <div class="input-group-append">
            <button :disabled="!(!!filter.value && !!filter.field && !!filter.type) && currentInputType(filter) != 'checkbox'" class="btn btn-outline-secondary">Применить фильтр</button>
        </div>
    </form>
    <div class="row" v-if="filters.length > 0">
        <div class="input-group col-sm" v-for="(filter, index) in filters" v-bind:key="index">
            <label class="form-control text-left" v-on:click.prevent="removeFilter(index)">
                {{ getFilterString(filter) }} 
            </label>
            <select class="form-control" v-model="filter.type">
                <option v-for="option in typesFilterfilter(filter)" v-bind:key="option.value" :value="option.value">
                    {{ option.name }}
                </option>
            </select> 

            <input v-if="currentInputType(filter) != 'lookup'" :type="currentInputType(filter)" class="form-control text-center" v-model="filter.value" required>
            <select v-else class="form-control" v-model="filter.value" required>
                <option v-for="option in fields.find(x => x.field == filter.field).lookup" v-bind:key="option.id" :value="option.id">
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>    
    
    <div class="row">
        <slot name="count" class="col-sm" :data="pagination"></slot>
        <div class="col-sm text-right mr-4">Количество: {{ pagination.count }}</div>
    </div>
    <div class="rounded shadow bg-white mr-3 flow-enabled" style="height:100vh">
        <table class="table table-hover table-striped rounded shadow">
            <thead>             
                <tr class="rounded shadow">
                    <th v-if="isCustomRow"></th>
                    <th scope="col" class="text-center" v-for="header of headers" v-bind:key="header.field">
                        <p class="d-inline">{{ header.name }} &nbsp;</p>
                        <fas-sort class="d-inline" v-if="header.sort" 
                            @click="sort(header.field)" 
                            v-bind:currentSort="currentSort" 
                            v-bind:currentSortDir="currentSortDir" 
                            v-bind:field="header.field"/>
                    </th>
                </tr>                
            </thead>
            <slot name="body" v-if="isCustomRow" :data="rowsData" :headers="headers" :getRowValue="getRowValue" :router="router" :routerOpen="routerOpen">
            </slot>
            <tbody v-else>
                <tr v-for="row of rowsData" v-bind:key="row.id">
                    <td v-bind:class="header.class ? header.class : ''" 
                        v-for="header of headers" v-bind:key="row.id+header.field" 
                        v-on:click.middle.prevent="routerOpen(getRowValue(row, header.router), header.router?.name)"
                        v-on:click.prevent="router(getRowValue(row, header.router), header.router?.name)">
                            {{ getRowValue(row, header) }}
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td colspan="1">
                        <div class="btn-toolbar mt-1 mb-3" role="toolbar" aria-label="Toolbar with button groups">
                            <div v-if="this.pagination.entities?.length != 0" class="btn-group me-2" role="group" aria-label="First group">
                                <button v-if="currentPage - 1 > 0" v-on:click="backPage" type="button" class="btn btn-outline-secondary">{{ currentPage - 1 }}</button>
                                <button disabled type="button" class="btn btn-outline-secondary">{{ currentPage }}</button>
                                <button v-if="this.pagination.totalPages > this.currentPage - 1" type="button" v-on:click="nextPage" class="btn btn-outline-secondary">{{ currentPage + 1 }}</button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                       
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="this.pagination.entities?.length == 0" class="text-center">
            Данные отсутствуют
        </div>
    </div>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import fasSort from '@/components/Sections/FasSort.vue';
import table from '@/mixins/table.js';
export default {
    name:"TSection",
    mixins: [table],
    props:["isHideButton", "isCustomRow", "isCustomIconPanel"],
    components: {
        fasSort
    },
    data(){
        return{
            uuid: uuidv4(),
        }
    },
    computed:{
        editPage: {
            get(){
                return this.schemaName.charAt(0).toUpperCase() + this.schemaName.slice(1) + 'Page';
            }
        },
    },
    methods:{
        typesFilterfilter(filter) {
            if(filter.field) {
                var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
                return this.filterTypes.filter(x => x.type == "all" || x.type.includes(type));
            }
            return [];
        },
        currentInputType(filter) {
            if(filter.field) {
                var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
                return type == "all" ? "text" : type;
            }
            return "text";
        }
    },
    mounted() {
        this.loadRows();
    }
}
</script>

<style scoped>
table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
}
</style>