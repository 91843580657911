<template>
    <base-detail 
        v-bind:title="'Продукты в заказе'"
        v-bind:rootId="rootId" 
        v-bind:rootColumn="rootColumn"
        v-bind:schemaName="'orderproduct'"
        v-bind:headers="headers"
        v-bind:entity="entity"
        v-bind:lookups="lookups"
        v-on:main-save="main-save"
        v-on:on-parrent-load="on-parrent-load">
        <template v-slot="{ entity, add }" class="input-group">
            <form v-on:submit.prevent="addProduct(entity, add)" class="input-group mb-3">
                <input class="form-control" type="text" :disabled="lookups.remains.length === 0"  v-model.lazy="productCode" list="products" required>
                <datalist id="products">
                    <option v-for="product in productsMap" :value="product.code" :key="product.id">{{ product.code }}</option>
                </datalist>
                <select class="form-control" v-model="entity.characterId" :disabled="lookups.remains.length === 0 || productCode == '' || productCode == null || productCode == undefined" required>
                    <option value="" disabled selected>Размер</option>
                    <option v-for="option in characters"
                        v-bind:key="option.id" 
                        :value="option.characterId">
                            {{ option.character.name }}
                    </option>
                </select>
                <input class="form-control" placeholder="Количество" :disabled="lookups.remains.length === 0 || productCode == '' || productCode == null || productCode == undefined" type="numeric" min="1" v-model="entity.quantity" required>                
                <div class="input-group-append">
                    <button
                        :disabled="lookups.remains.length === 0 || productCode == '' || productCode == null || productCode == undefined"
                        class="btn btn-outline-secondary" 
                        type="submit">Добавить</button>
                </div>
            </form>
        </template>
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "OrderProductDetail",
    components:{ BaseDetail },
    emits:["on-parrent-load", "main-save"],
    props:{
        rootId: { type:String, require:true },
        rootColumn: { type:String, require:true },
        enabled: { type: Boolean }
    },
    computed:{
        characters:{
            get(){
                var remain = [...this.lookups.remains.filter(x => x.productId === this.entity.productId && x.quantity > 0)];
                return remain.sort((a, b) => a.character.name - b.character.name);
            }
        },
        productsMap:{
            get(){
                return [...this.products].map(x => x.product).filter(x => x.code.includes(this.productCode));
            }
        },
        products:{
            get(){
                var remain = [...this.lookups.remains.filter(x => x.quantity > 0)]
                return remain.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.productId === item.productId
                    ))
                );
            }
        },
        productCode:{
            get(){
                return this.entity.productCode;
            },
            set(value){
                let remains = this.products.find(x => x.product.code == value)
                this.entity.productId = remains?.productId;
                this.entity.productCode = value;
                this.entity.price = remains?.product.wholeSalePrice;
            }
        }
    },
    methods:{
        loadLookups() {
            this.$store.dispatch('section', { table: "productremains" })
            .then((response) => {
                this.lookups.remains = response.data.data;
            });
        },

        addProduct(entity, callback) {
            if(!this.enabled)
                this.save(entity, callback)
            else 
                this.add(entity, callback);
        },

        add(entity, callback){
            this.$store.dispatch("post", 
            { 
                table:"Order", 
                method:"addproduct", 
                body: JSON.parse(JSON.stringify(entity)) 
            }).then(response => {
                this.loadLookups();
                callback(response.data.data);
                this.$emit("on-parrent-load");
            })
            .catch(error => this.$toast.error(error.response.data.message));
        },

        save(entity, callback){
            new Promise((resolve, reject) =>{
                this.$emit("main-save", null, resolve, reject);
            }).then(() => this.add(entity, callback));
        }
    },
    mounted(){
        this.loadLookups();
    },
    data(){
        return {
            lookups:{
                remains:[]
            },
            entity:{
                activityId: this.rootId,
                characterId:"",
                productId:"",
                productCode:"",
                quantity:null,
                price:null
            },
            headers: [
                { field:"product.image", name: "Изображение", image:true},
                { field:"product.code", name:"Продукт", sort:true },
                { field:"productRemain.character.name", name:"Размер", sort:true },
                { field:"price", name:"Цена", sort:true, input: { type: "numeric" }, enabeled: true},
                { field:"quantity", name:"Количество", sort:true, input: { type: "numeric" }, enabeled: true},
                { field:"id", name:"Действие", action: true }
            ]
        };
    }
}
</script>