<template>
    <vSelect :options="cities" label="label" v-model="city" @search="getCities" :reduce="city => city" :required="required"/>           
</template>

<script>
import vSelect from "vue-select";

export default {
    name:"s-city",    
    emits: ['update', "required"],
    props: { entity: Object },
    components:{ vSelect },
    data(){
        return {
            cities:[]
        }
    },
    computed:{
        city:{
            get() {
                return { code: this.entity.city, label:this.entity.cityName };
            },            
            set(value) {
                this.$emit("update", value)
            }
        }
    },
    methods:{
        getCities(name){
            if(name) {
                this.$store.dispatch('getAPI', { controller: "address", method: "getcities/" + name })
                .then((response) => {
                    this.cities = response.data.result.data[0].addresses.map(x => this.mapCity(x));
                }); 
            }
        },
        mapCity(data){
            return { code: data.ref, label: data.present };
        },
    }
}
</script>