<template>
    <form v-on:submit.prevent="sendMessage"  class="container mb-3 bg-white rounded shadow" v-bind:isHideCustom="true">
        <div class="text-center mb-3 pt-2">
            <label class="form-label">Тип сообщения</label>
            <select class="form-control" v-model="typeId" required>
                <option v-for="option in typesFilter" v-bind:key="option.id" :value="option.id">
                    {{ option.name }}
                </option>
            </select>
        </div>
        <div v-if="typeId == 1" class="mb-3">
            <label>Период отправки TTH</label>
            <div class="row">
                <div class="col-sm input-group">
                    <label class="form-control">С</label>
                    <input type="date" v-model="period.start" class="form-control" placeholder="Начало" aria-describedby="button-addon2" required>
                </div>
                <div class="col-sm input-group">
                    <label class="form-control">До</label>
                    <input type="date" v-model="period.end" class="form-control" placeholder="Конец" aria-describedby="button-addon2" required>
                </div>
            </div>
        </div>
        <div v-else-if="typeId == 0 && !contactId" class="mb-3">
            <label class="form-label">Контакт</label>
            <select class="form-control" v-model="currentContactId" required>
                <option v-for="option in contacts" v-bind:key="option.id" :value="option.id">
                    {{ option.fullName }}
                </option>
            </select>
        </div>
        <textarea v-if="typeId != 1" type="text" v-model="text" class="form-control mb-3" placeholder="Сообщение" aria-describedby="button-addon2"></textarea>        
        <button class="btn btn-outline-secondary mb-3" type="submit" id="button-addon2">Отправить</button>
    </form>
</template>

<script>
export default {
    name: "SenderDetail",
    props:{
        contactId: { type:String },
    },
    computed:{
        typesFilter: {
            get() {
                if(this.contactId)
                    return this.types.filter(x => x.id == 0);
                else 
                    return this.types;
            }
        }
    },
    data(){
        return {
            types: [{ id:0, name:"Персонально" }, {id:1, name:"Отправка ТТН за период" }, { id: 2, name: "Рассылка всем"}],
            contacts:[],
            currentContactId: null,
            text: "",
            typeId: 2,
            period:{
                start: "",
                end: ""
            }          
        };
    },
    mounted() {
        this.loadRows();        
    },    
    methods:{
        loadRows() {
            this.$store.dispatch('section', { table: "contact", body: {}})
                .then((response) => {
                    this.contacts =  response.data.data;
                    this.currentContactId = this.contactId;
            });
        },
        sendMessage() {
            switch(this.typeId){
                case 1:
                    this.sendPeriodTTNMessage()
                    break;
                case 2:
                    this.sendAllMessage();
                    break;
                default:{
                    this.sendPersonalMessage();
                    break;
                }
            }
        },
        sendPersonalMessage(){
            if(!this.currentContactId){
                this.$toast.error("Отсутствует идентификатор клиента");
                return;
            }
            this.$store.dispatch("postAPI", 
            { 
                controller:"Sender", 
                method:"SendMessage", 
                body: { contactId: this.currentContactId, text: this.text } 
            })
            .then(() => this.$toast.success("Сообщение успешно отправлено"))
            .catch(error => this.$toast.error(error.response.data.message));
        },
        sendPeriodTTNMessage(){
            if(!this.period.start || !this.period.end || (this.period.end < this.period.start)){
                this.$toast.error("Периоды заданы не корректно");
                return;
            }
            this.$store.dispatch("postAPI", 
            { 
                controller:"Sender", 
                method:"SendTTNByPeriod", 
                body: { start: this.period.start, end: this.period.end } 
            })
            .then((response) => this.$toast.success("Сообщений отправлено: " + response.data.data))
            .catch(error => this.$toast.error(error.response.data.message));
        },
        sendAllMessage(){
            if(!this.text){
                this.$toast.error("Отсутствует текст сообщения");
                return;
            }
            this.$store.dispatch("postAPI", 
            { 
                controller:"Sender", 
                method:"SendAllMessage", 
                body: { text: this.text } 
            })
            .then((response) => this.$toast.success("Сообщений отправлено: " + response.data.data))
            .catch(error => this.$toast.error(error.response.data.message));
        }
    },
}
</script>