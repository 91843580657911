import { createWebHistory, createRouter } from "vue-router";
import cookies from 'js-cookie';

import Home from "@/views/Home.vue";
import Catalog from "@/views/Catalog.vue";
//===========Contact============
import ContactSection from "@/views/contacts/ContactSection.vue";
import ContactPage from "@/views/contacts/ContactPage.vue";

//===========Contact============
import ProductSection from "@/views/products/ProductSection.vue";
import ProductPage from "@/views/products/ProductPage.vue";

//===========Order============
import OrderSection from "@/views/orders/OrderSection.vue";
import OrderPage from "@/views/orders/OrderPage.vue";

//==========Promotion=========
import PromotionSection from "@/views/promotions/PromotionSection.vue";
import PromotionPage from "@/views/promotions/PromotionPage.vue";


//==========Subscribe=========
import SubscribeSection from "@/views/subscribes/SubscribeSection.vue";

//==========Withdrawal========
import WithdrawalSection from "@/views/withdrawal/WithdrawalSection.vue";
import WithdrawalPage from "@/views/withdrawal/WithdrawalPage.vue";

//==========PaymentDetail========
import PaymentDetailSection from "@/views/payment/detail/PaymentDetailSection.vue";
import PaymentDetailPage from "@/views/payment/detail/PaymentDetailPage.vue";

import Login from "@/views/Login.vue";
import SignOut from "@/views/SignOut.vue";

//==========Message===========
import MessagePage from "@/views/messages/MessagePage.vue";
import MessageSection from "@/views/messages/MessageSection.vue";

//==========Settings===========
import ProductCategoryPage from "@/views/settings/ProductCategoryPage.vue";
import ProductCategorySection from "@/views/settings/ProductCategorySection.vue";

import SystemSettingPage from "@/views/settings/SystemSettingPage.vue";
import SystemSettingSection from "@/views/settings/SystemSettingSection.vue";

import DeliveryPage from "@/views/settings/delivery/DeliveryPage.vue";
import DeliverySection from "@/views/settings/delivery/DeliverySection.vue";

import HangfirePage from "@/views/settings/HangfirePage.vue";

import SwaggerPage from "@/views/settings/SwaggerPage.vue";

//============BOT===============
import LoginPage from "@/views/bots/LoginPage.vue";
import HomePage from "@/views/bots/HomePage.vue";
import Reservation from "@/views/bots/views/Reservation.vue";
import Reservations from "@/views/bots/views/Reservations.vue";
import OrderBot from "@/views/bots/views/Order.vue";
import OrdersBotPage from "@/views/bots/views/Orders.vue";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta:{
            guest:true,
            user:false
        }
    },
    {
      path:"/singout",
      name:"SignOut",
      component: SignOut,
      meta:{}
    },
    {
      path:"/catalog",
      name: "Catalog",
      component: Catalog
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        children:[
          {
            path: "contact",
            name: "Contact",
            component: ContactSection,
          },
          {
            path:"contact/:id",
            name:"ContactPage",
            component:ContactPage
          },
          {
            path: "product",
            name: "Product",
            component: ProductSection,
          },
          {
            path:"product/:id",
            name:"ProductPage",
            component:ProductPage
          },
          {
            path: "order",
            name: "Order",
            component: OrderSection,
          },
          {
            path:"order/:id",
            name:"OrderPage",
            component:OrderPage
          },
          {
            path:"promotion",
            name:"Promotion",
            component: PromotionSection
          },
          {
            path:"promotion/:id",
            name:"PromotionPage",
            component:PromotionPage
          },
          {
            path: "subscribe",
            name: "Subscribe",
            component:SubscribeSection
          }, 
          {
            path:"withdrawal/:id",
            name:"WithdrawalPage",
            component:WithdrawalPage
          },
          {
            path: "withdrawal",
            name: "Withdrawal",
            component:WithdrawalSection
          },          
          {
            path:"paymentdetail/:id",
            name:"PaymentDetailPage",
            component:PaymentDetailPage
          },
          {
            path: "paymentdetail",
            name: "PaymentDetail",
            component: PaymentDetailSection,
          },
          {
            path: "message/:id",
            name: "MessagePage",
            component:MessagePage
          },         
          {
            path: "message",
            name: "Message",
            component:MessageSection
          },          
          {
            path: "productCategory/:id",
            name: "ProductCategoryPage",
            component: ProductCategoryPage
          },
          {
            path: "category",
            name: "ProductCategory",
            component: ProductCategorySection
          },
          {
            path: "setting/:id",
            name: "SystemSettingPage",
            component: SystemSettingPage
          },
          {
            path: "setting",
            name: "SystemSetting",
            component: SystemSettingSection
          },
          {
            path: "delivery/:id",
            name: "DeliveryConfigurationPage",
            component: DeliveryPage
          },
          {
            path: "delivery",
            name: "DeliveryConfigurationSection",
            component: DeliverySection
          },
          {
            path:"hangfire",
            name:"Hangfire",
            component: HangfirePage
          },
          {
            path:"swagger",
            name:"Swagger",
            component: SwaggerPage
          }   
        ],
        meta:{
            user:true
        }
    },
    {
      path: "/bot/:id",
      name: "Bot",
      component: LoginPage
    },
    {
      path: "/bot/:id/:page",
      name: "Bot",
      component: LoginPage
    },
    {
      path: "/bot",
      name: "HomeBot",
      component: HomePage,
      children:[{
        path:"",
        name:"ReservationsPage",
        component: Reservations
      },
      {
        path:"reservation",
        name:"ReservationPage",
        component: Reservation
      },
      {
        path:"order/:id",
        name:"OrderBotPage",
        component: OrderBot
      },
      {
        path:"order",
        name:"OrdersBotPage",
        component: OrdersBotPage
      }
    ],
      meta: {
        bot:true
      },
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

const guestNext = function(next) {
  if(!cookies.get('Authorization')) {
    next();
  }
  else if(cookies.get("ImBot")){
    next({path:"/singout"});
  } 
  else {
    next({ path:"/contact" });
  }
};

const userNext = function(to, next) {
  if (!cookies.get('Authorization')) {
    next({
      path: "/login",
    });
  } else {
    if(!cookies.get("ImBot")) {
      if(to.path === "/login") {
        next({
          path: "/contact",
        })
      }
      else {
        next();
      }
    } else {
      next({path:"/singout"});
    }
  }
}

const botNext = function(next) {
    if(cookies.get("Authorization") && cookies.get("ImBot")) {
      next();
    } else {
      next({ path: "/login"})
    }
};

router.beforeEach((to, _, next) => {
    if (to.matched.some((record) => record.meta.bot)) {
      botNext(next);
    } 
    else if (to.matched.some((record) => record.meta.guest)) {
      guestNext(next);
    } 
    else if (to.matched.some((record) => record.meta.user)) {
      userNext(to, next);
    } else {
      next();
    }
  });

export default router;