<template>
  <div>
      <form v-on:submit.prevent="addProduct" class="input-group mb-3">
        <select class="form-control" v-model="productId" required>
            <option value="" disabled selected>Продукт</option>
            <option v-for="option in products"
                v-bind:key="option.productId" 
                :value="option.productId">
                    {{ option.product.code }}
            </option>
        </select>
        <select class="form-control" v-model="characterId" required>
            <option value="" disabled selected>Размер</option>
            <option v-for="option in characters"
                v-bind:key="option.id" 
                :value="option.characterId">
                    {{ option.character.name }}
            </option>
        </select>
        <!--<input class="form-control" placeholder="Цена за один" type = "number" :min="minPrice" v-model="entity.price" required>-->

        <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="submit">Добавить</button>
        </div>
    </form>
        <button v-if="entities.length > 0" type="button" class="btn btn-sm btn-outline-success mb-3" v-on:click="confirm">Подтвердить резерв</button>
        <div class="table-responsive bg-white rounded shadow flow-enabled" style="max-height: 250px;">
            <table class="table table-sm table-hover sortable">
                <thead>
                    <tr>
                        <th class="text-center" v-for="header of headers" v-bind:key="header.field">
                            <p class="d-inline">{{ header.name }} &nbsp;</p>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) of entities" v-bind:key="index">
                        <td class="text-center">                            
                        <select  :disabled="true" class="form-control" v-model="row.productId">
                            <option v-for="option in remains" v-bind:key="option.productId" :value="option.productId">
                                {{ option.product.name }}
                            </option>
                        </select>
                        </td>
                        <td>
                        <select :disabled="true" class="form-control" v-model="row.characterId">
                            <option v-for="option in remains" v-bind:key="option.characterId" :value="option.characterId">
                                {{ option.character.name }}
                            </option>
                        </select>
                        </td>
                        <td>
                            <p>{{ row.quantity }}</p>
                        </td>
                        <!--<td>
                            <p>{{ row.price }}</p>
                        </td>-->
                        <td class="text-center">                            
                            <button type="button" class="btn btn-sm btn-danger" v-on:click="removeProduct(index)">X</button>
                        </td> 
                    </tr>
                </tbody>
            </table>
        </div>
  </div>
</template>

<script>
export default {
    name:"Reservation",
    computed:{
        characters:{
            get(){
                var remain = [...this.remains.filter(x => x.productId === this.entity.productId && x.quantity > 0)];
                return remain.sort((a, b) => a.character.name - b.character.name);
            }
        },
        products:{
            get(){
                var remain = [...this.remains.filter(x => x.quantity > 0)]
                return remain.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.productId === item.productId
                    ))
                );
            }
        },
        characterId:{
            get(){
                return this.entity.characterId;
            },
            set(value) {
                this.entity.characterId = value;
                this.setMinPrice();
                this.setMaxQuantity();
            }
        },
        productId:{
            get(){
                return this.entity.productId;
            },
            set(value) {
                this.entity.productId = value;
                this.setMinPrice();
                this.setMaxQuantity();
            }
        },
        requestBody:{
            get(){
                return {
                    products: JSON.parse(JSON.stringify(this.entities))
                };
            }
        }
    },
    data() {
        return {
            minPrice: 0,
            maxQuantity: 0,
            entity:{
                productId: "",
                characterId: "",
                quantity: 1,
                price: 0,
            },
            remains:[],
            entities:[],
            headers:[
                { name:"Продукт" },
                { name:"Размер" },
                { name:"Цена" }
            ]
        }
    },
    methods:{
        confirm(){
            this.$http.post(window.location.origin + "/api/v1/order/set", this.requestBody)
            .then(response => {
                    this.$toast.success("Номер резерва: "+ response.data.data);
                    this.entities = [];
                    this.loadLookups();
                })
            .catch(error => 
            {
                this.$toast.error(error.response.data.message);
                this.loadLookups();
            });
        },

        setMaxQuantity(){
            this.maxQuantity = this.remains.find(x => x.productId === this.entity.productId && x.characterId === this.entity.characterId)?.quantity ?? 0;
        },
        setMinPrice(){
            this.minPrice = this.remains.find(x => x.productId === this.entity.productId && x.characterId === this.entity.characterId)?.product?.wholeSalePrice ?? 0;
        },
        addProduct(){
            this.entities.push(this.entity);
            this.entity = {
                productId: "",
                characterId: "",
                quantity: 1,
                price: 0
            };
        },
        removeProduct(id){
            this.entities = this.entities.filter((_, index) => index !== id);
        },
        loadLookups() {
            this.$store.dispatch('section', { table: "productremains" })
            .then((response) => {
                this.remains = response.data.data;
            });
        },
    },
    mounted(){
        this.$store.dispatch('section', { table: "contact" })
        this.loadLookups();
    }
}
</script>