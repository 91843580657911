<template>
    <div class="rounded shadow bg-white mr-3 flow-enabled">
        <table class="table table-hover table-striped rounded shadow">
            <thead>             
                <tr class="rounded shadow">
                    <th scope="col" class="text-center" v-for="header of headers" v-bind:key="header.field">
                        <p class="d-inline">{{ header.name }} &nbsp;</p>
                    </th>
                </tr>                
            </thead>
            <tbody>
                <tr v-for="row of rows" v-bind:key="row.position">
                    <td v-for="header of headers" v-bind:key="row.position+header.field"> 
                        {{ getRowValue(row, header) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import rows from '@/mixins/rows.js';
export default {
    name:"OrderAnalyst",
    mixins: [rows],
    data(){
        return {
            rows:[],
            headers:[
                { field:"position", name:"Рейтинг" },
                { field:"contact.fullName", name:"Контакт"},
                { field:"count", name:"Количество"},
                { field:"contact.personalBonus", name:"Бонус"}
            ]
        }
    },
    methods:{
        loadRows() {
            this.$store.dispatch('post', { table: 'order', method: "analytics", body: [
                    {
                        "field": "StatusId",
                        "value": "1bf61851-347c-4d0d-a62e-d488dce9c0da",
                        "operation": 2
                    },
                    
                    {
                        "field": "StatusId",
                        "value": "7bf61851-347c-4d0d-a62e-d488dce9c0da",
                        "operation": 2
                    },
                    
                    {
                        "field": "StatusId",
                        "value": "8bf61851-347c-4d0d-a62e-d488dce9c0da",
                        "operation": 2
                    },

                    
                    {
                        "field": "StatusId",
                        "value": "9bf61851-347c-4d0d-a62e-d488dce9c0da",
                        "operation": 2
                    },

                    {
                        "field": "StatusId",
                        "value": "2bf61851-347c-4d0d-a62e-d488dce9c0da",
                        "operation": 2
                    },
                    
                    {
                        "field": "StatusId",
                        "value": "1bf21861-387c-3d0d-a62e-d488dce9c0da",
                        "operation": 2
                    }
                ]})
                .then((response) => {
                    this.rows = response.data;
            });
        },
    },
    mounted() {
        this.loadRows();
    }
}
</script>