<template>
  <div class="container">
        <div class="mb-3 text-center">
            <label>Номер: {{ entity.number }}</label>
        </div>
        <form class="container flow-show" v-on:submit.prevent="confirm">
            <div class="text-left mb-3">
                <div class="mb-3">Продукты</div>
                <form v-on:submit.prevent="addProduct" class="input-group mb-3" v-if="isEditable">                    
                    <input class="form-control" type="text" v-model="productCode" list="products" required>
                    <datalist id="products">
                        <option v-for="product in productsMap" :value="product.code" :key="product.id">{{ product.code }}</option>
                    </datalist>
                    <select class="form-control" v-model="product.productRemainId" required>
                        <option value="" disabled selected>Размер</option>
                        <option v-for="option in lookupCharacters"
                            v-bind:key="option.id" 
                            :value="option.id">
                                {{ option.character.name }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" :disabled="!isEditable" type="submit">Добавить</button>
                    </div>
                </form>

                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th class="text-center" v-for="header of productHeaders" v-bind:key="header.name">
                                <p class="d-inline">{{ header.name }} &nbsp;</p>
                            </th>
                            <th v-if="isEditable"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) of products" v-bind:key="index">
                            <td class="text-center">      
                                <label class="form-control text-center">{{ row.product.code }}</label>                      
                            </td>
                            <td>                                
                                <label class="form-control text-center">{{ row.productRemain.character?.code }}</label>   
                            </td>
                            <td class="text-center" v-if="isEditable">                            
                                <button type="button" class="btn btn-sm btn-danger" :disabled="!isEditable" v-on:click="rmProduct(index)">X</button>
                            </td> 
                        </tr>
                    </tbody>
                </table>                
                <div class="container text-center" v-if="products.length === 0">Продукты отсутствуют в заказе</div>
            </div>
            <div class="text-left">
                <div class="mb-3">Подарки</div>
                <form v-on:submit.prevent="addPromotion" class="input-group mb-3" v-if="isEditable">
                    <select class="form-control" v-model="promotionId" required>
                        <option value="" disabled selected>Подарок</option>
                        <option v-for="option in promotionsFilter"
                            v-bind:key="option.id" 
                            :value="option.id">
                                {{ option.name }}
                        </option>
                    </select>
                    <div class="input-group-append">
                        <button :disabled="!isEditable" class="btn btn-outline-secondary" type="submit">Добавить</button>
                    </div>
                </form>

                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th class="text-center" v-for="header of promotionHeaders" v-bind:key="header.name">
                                <p class="d-inline">{{ header.name }} &nbsp;</p>
                            </th>
                            <th v-if="isEditable"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) of promotionsInEntity" v-bind:key="index">
                            <td class="text-center">                            
                                <select :disabled="true" class="form-control text-center" v-model="row.promotionId">
                                    <option v-for="option in promotions" v-bind:key="option.id" :value="option.id">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </td>
                            <td class="text-center">                            
                                <button type="button" v-if="isEditable" class="btn btn-sm btn-danger" v-on:click="rmPromotion(index)">X</button>
                            </td> 
                        </tr>
                    </tbody>
                </table>
                <div class="container text-center mb-3" v-if="promotionsInEntity.length === 0">Подарки отсутствуют в заказе</div>
            </div>

            <div class="row mb-3">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Служба доставки</label>
                    <select :disabled="deliveryServices.length === 0 || !isEditable" class="form-control" v-model="entity.deliveryServiceId" required>
                        <option value="" disabled selected></option>
                        <option v-for="option in deliveryServices" v-bind:key="option.id" :value="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Тип оплаты</label>
                    <select :disabled="deliveryTypesFilter.length === 0 || deliveryServiceId === null || !isEditable" class="form-control" v-model="deliveryTypeId" required>
                        <option value="" disabled selected></option>
                        <option v-for="option in deliveryTypesFilter" v-bind:key="option.id" :value="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Город</label>
                    <input v-if="entity.deliveryServiceId != '1bf61851-347c-4d0d-a62e-d488dce9c0da'" type="text" class="form-control" :disabled="!isEditable" v-model="entity.cityName" required>
                    <scity v-else :entity="entity" @update="(value) => { entity.city = value.code; entity.cityName = value.label; }" :required="entity.statusId != '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId != '2bf61851-347c-4d0d-a62e-d488dce9c0da'"/>
                </div>
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Отделение почты / индекс / адрес доставки</label>
                    <input type="text" class="form-control" :disabled="!isEditable" v-model="entity.address" required>
                </div>
            </div>
            <div class="row">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Получатель</label>
                    <input type="text" class="form-control" :disabled="!isEditable" v-model="entity.recipient" required>
                </div>
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Мобильный телефон</label>
                    <input pattern="^\+380[0-9]{9,10}" type="tel" placeholder="+380111111111" class="form-control" :disabled="!isEditable" v-model="entity.recipientPhone" required>
                </div>
            </div>
            <div class="row">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Сумма оплаты</label>
                    <input type="number" :min="minPaymentAmount" :max="maxPaymentAmount" class="form-control" :disabled="!isEditable" v-model="entity.paymentAmount" :required="minCashPaidAmount > 0 && entity.bonusPaidAmount != 0">
                </div>
                <div v-if="entity.paymentAmount > 0" class="col-sm mb-3 text-left">
                    <label class="form-label">Дата оплаты</label>
                    <input type="date" class="form-control" :disabled="!isEditable" v-model="paymentDate" required>
                </div>
                <div v-if="entity.paymentAmount > 0" class="col-sm mb-3 text-left">
                    <label class="form-label">Время оплаты</label>
                    <input type="time" class="form-control" :disabled="!isEditable" v-model="paymentTime" required>
                </div>
            </div>
            <div v-if="entity.paymentAmount > 0" class="row mb-3">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Куда оплатили</label>
                    <select class="form-control" v-model="entity.paymentDetailId" required>
                        <option disabled> </option>
                        <option v-for="option in fops" v-bind:key="option.id" :value="option.id">
                            {{ option.name }} {{ option.number}}                      
                        </option>
                    </select>
                </div>
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">ФИО плательщика</label>
                    <input type="text" class="form-control" :disabled="!isEditable" v-model="entity.paymentPayer" required>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Сумма наложеного платежа</label>
                    <input type="number" :disabled="isFullPayment || !isEditable" :min="minCashPaidAmount" class="form-control" v-model="entity.cashPaidAmount" :required="minCashPaidAmount > 0 && entity.bonusPaidAmount != 0">
                </div>
                <div v-if="this.balance > 0" class="col-sm mb-3 text-left">
                    <label class="form-label">Оплата балансом</label>
                    <input type="number" min="0" :max="balance" class="form-control" :disabled="!isEditable" v-model="entity.bonusPaidAmount" required>
                </div>               
            </div>                     
            <div class="row mb-3">
                <div class="col-sm mb-3 text-left">
                    <label class="form-label">Комментарий</label>
                    <textarea :disabled="!isEditable" class="form-control" v-model="entity.comment" />
                </div>              
            </div>
            <div class="text-center mb-3" v-if="isEditable">
                <button :disabled="products.length === 0 || !isEditable || isBlockSubmit" type="submit" class="btn btn-block btn btn-outline-success">Подтвердить</button>                 
            </div>
            <div class="text-center" style="margin-top:100px"><p> &nbsp; </p></div>
        </form>
  </div>
</template>

<script>
import scity from "@/components/scity.vue";
import address from '@/mixins/novaposhta/address.js';
(function() {
    function pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

    Date.prototype.toISOString = function() {
    return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds()) +
        '.' + (this.getMilliseconds() / 1000).toFixed(3).slice(2, 5);
    };
  }());

export default {
    name:"OrderBot",
    mixins: [address],
    components: {scity},
    computed:{
        productCode:{
            get(){
                return this.searchProduct;
            },
            set(value){
                this.productId = this.lookupProducts.find(x => x.product.code == value)?.productId;
                this.searchProduct = value;
            }
        },    
        productId:{
            get(){
                return this.product.productId;
            },
            set(value){
                this.product.productId = value;
                if(value) {
                    this.product.price = this.lookupProducts.find(x => x.productId == value).product.wholeSalePrice ?? 0;
                }
            }
        },
        deliveryTypeId:{
            get(){
                return this.entity.deliveryTypeId;
            },
            set(value) {
                this.entity.deliveryTypeId = value;
                if(this.isFullPayment) 
                    this.entity.cashPaidAmount = 0;
            }
        },
        isEditable:{
            get(){
                return this.entity.statusId === '2bf61851-347c-4d0d-a62e-d488dce9c0da' 
                || this.entity.statusId === '1bf61851-347c-4d0d-a62e-d488dce9c0da' 
                || this.action == null;
            },
        },
        promotionId:{
            get(){
                return this.promotion.promotionId;
            },
            set(value){
                this.promotion.promotionId = value;
                this.promotion.typeId = this.promotions.find(x => x.id === value).typeId;
                this.promotion.value = this.promotions.find(x => x.id === value).value;
            }            
        },
        lookupCharacters:{
            get(){
                var remain = [...this.remains.filter(x => x.productId === this.product.productId && x.quantity > 0)];
                return remain.sort((a, b) => a.character.name - b.character.name);
            }
        },
        productsMap:{
            get(){
                return [...this.lookupProducts].map(x => x.product).filter(x => x.code.includes(this.productCode));
            }
        },
        lookupProducts:{
            get(){
                var remain = [...this.remains.filter(x => x.quantity > 0)]
                return remain.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.productId === item.productId
                    ))
                );
            }
        },
        promotionsFilter:{
            get(){
                let productPromotionCount = this.products.filter(x => x.product?.isPromotion).length;
                if(this.isFullPayment && productPromotionCount > 0) {
                    let proms = [...this.promotions];
                    if(this.promotionsInEntity.map(x => x.typeId).includes('2bf61851-347c-4d0d-a62e-d488dce9c0da')){
                        let pie = [...this.promotionsInEntity.filter(x => x.typeId === '2bf61851-347c-4d0d-a62e-d488dce9c0da')];
                        if(pie.length < productPromotionCount)
                            proms = proms.filter(x => x.typeId === '1bf61851-347c-4d0d-a62e-d488dce9c0da' || pie.map(x => x.promotionId).includes(x.id));
                        else
                            proms = proms.filter(x => x.typeId === '1bf61851-347c-4d0d-a62e-d488dce9c0da')
                    }
                    return proms;
                } else {
                    return [...this.promotions.filter(x => x.typeId !== '2bf61851-347c-4d0d-a62e-d488dce9c0da')];
                }
            }
        },
        request:{
            get(){
                var obj = JSON.parse(JSON.stringify(this.entity));
                obj.products = JSON.parse(JSON.stringify(this.products));
                obj.promotions = JSON.parse(JSON.stringify(this.promotionsInEntity));
                return obj;
            }
        },
        isFullPayment:{
            get(){
                return this.entity.deliveryTypeId === "1bf61851-347c-4d0d-a62e-d488dce9c0da";
            }
        },
        deliveryTypesFilter:{
            get(){
                if(this.entity?.deliveryServiceId === '1bf61851-347c-4d0d-a62e-d488dce9c0da' 
                    && this.promotionsInEntity.filter(x => x.typeId === '2bf61851-347c-4d0d-a62e-d488dce9c0da').length === 0){
                    return this.deliveryTypes;
                } else {
                    return [...this.deliveryTypes.filter(x => x.id !== '2bf61851-347c-4d0d-a62e-d488dce9c0da')];
                }
            }
        },
        minPaymentAmount: {
            get(){
                return this.isFullPayment 
                    ? this.minCashPaidAmount
                    : (((this.products.length - 1) * 100) + 150) - this.entity.bonusPaidAmount;
            }
        },
        maxPaymentAmount: {
            get(){
                var sum = this.promotionsInEntity.filter(x => x.value < 0).map(x => x.value).reduce((a, b) => a + b, 0);
                var sumCash = this.products.map(x => x.price).reduce((a, b) => a + b, 0);
                var max = (sumCash + Math.abs(sum)) - this.entity.bonusPaidAmount;                 
                return this.isFullPayment ? max: Number.MAX_SAFE_INTEGER;
            }
        },

        minCashPaidAmount:{
            get(){
                var sum = this.promotionsInEntity.filter(x => x.value < 0).map(x => x.value).reduce((a, b) => a + b, 0)
                var cashPaidAmount = (this.products.map(x => x.price).reduce((a, b) => a + b, 0) + Math.abs(sum)) - this.entity.bonusPaidAmount - this.entity.paymentAmount;
                return cashPaidAmount;
            }
        },

        paymentTime: {
            get() {
                return this.time;
            },
            set(value) {
                this.time = value;
                let date = this.paymentDate +"T" + value +":00";
                this.entity.paymentTime = new Date(date)
            }            
        },
        paymentDate:{
            get() {
                return this.date;
            },
            set(value) {
                if(this.paymentTime) {
                    let date = value+"T"+this.paymentTime;
                    this.entity.paymentTime = new Date(date);
                } else {
                    this.entity.paymentTime = new Date(value);
                }
                this.date = value;
            }
        }
    },
    data(){
        return {
            isBlockSubmit: false,
            searchProduct:"",
            action: null,
            balance: 0,
            cashPaidAmount:0,
            product: { productId: null, productRemainId: null, productRemain: null, quantity:1 },
            promotion: { promotionId: null, id: null, typeId: null, value: 0 },
            time: "00:00",
            date: null,
            entity:{ id: null },
            remains:[],
            products:[],
            productHeaders:[
                { name:"Продукт" },
                { name:"Размер" },
            ],
            promotionHeaders:[
                { name: "Подарок" }
            ],
            fops:[],
            promotionsInEntity:[],
            promotions:[],
            deliveryTypes:[],
            deliveryServices: [],
            cities:[]
        }
    },
    mounted() {
        this.onLoadData(this.$route.params.id);
        this.onLoadLookups();
    },
    methods: {
        addPromotion(){
            this.promotionsInEntity.push(this.promotion);
            this.promotion = { promotionId: null, id: null, value: 0};
        },
        rmPromotion(id){
            this.promotionsInEntity = this.promotionsInEntity.filter((_, index) => index !== id);
        },
        addProduct() {
            this.product.productRemain = this.remains.find(x => x.id == this.product.productRemainId);
            this.product.product = this.product.productRemain.product;
            this.products.push(this.product);
            this.cashPaidAmount = this.products.map(x => x.price).reduce((a, b) => a + b, 0);
            this.product = { productId: null, productRemainId: null, productRemain: null, quantity:1, price: 0 };
        },
        rmProduct(id){
            this.products = this.products.filter((_, index) => index !== id);
            this.cashPaidAmount = this.products.map(x => x.price).reduce((a, b) => a + b, 0);
        },
        confirm() {
            this.isBlockSubmit = true;
            var path = window.location.origin + "/api/v1/order/" + (this.action === "add" ? "create" : "confirm");
            this.$http.post(path, this.request)
                .then((response) => {                    
                    this.entity = response.data.data;
                    this.$route.params.id = this.entity.id;
                    this.cashPaidAmount = response.data.data.totalCost;
                    if(response.data.status)
                        this.$toast.success("Заказ успешно создан");
                    else
                        this.$toast.error(response.data.message);

                    this.isBlockSubmit = false;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.message);
                    this.isBlockSubmit = false;
            });
        },
        onLoadLookups(){
            this.$store.dispatch('section', { table: "deliveryType" })
            .then((response) => {
                this.deliveryTypes = response.data.data;
            });
            this.$store.dispatch('section', { table: "deliveryService" })
            .then((response) => {
                this.deliveryServices =  response.data.data;
            });
            this.$store.dispatch('section', { table: "promotion" })
            .then((response) => {
                this.promotions =  response.data.data;
            });
            this.$store.dispatch('get', { table: "productremains", method: "sectionV2" })
            .then((response) => {
                this.remains = response.data.data;
            });
            this.$store.dispatch('section', { table: "paymentdetail" })
            .then((response) => {
                this.fops = response.data.data.filter(x => x.isActive);
            });
        },
        onSubLoadData(id){
            this.$store.dispatch("getFilter", { table: 'OrderProduct', body: { id: id, rootColumn: "ActivityId" }})
                .then(response => this.products = response.data.data);
            this.$store.dispatch("get", { table:"transaction", method: 'balance?contactId='+this.entity.contactId })
                .then(response => { this.balance = response.data.data; });
            this.$store.dispatch("getFilter", { table: 'PromotionInOrder', body: { id: id, rootColumn: "OrderId" }})
                .then(response => this.promotionsInEntity = response.data.data);        
        },
        onLoadData(id){
            return this.$store.dispatch('index', { table:'order', body: { id: id } })
            .then((response) => {
                var result = response.data;
                this.entity = result.data;
                this.$route.params.id = this.entity.id;
                this.cashPaidAmount = result.data.totalCost;
                this.action = result.status ? "update" : "add"; 
                this.onSubLoadData(id);
            }).catch(() => {
                this.$toast.error("Системная ошибка. Не удалось получить информацию");
            });
        },
    }
}
</script>
<style scoped>
.flow-show{
    overflow-y: scroll;
    max-height: -moz-calc(100vh - (150px));
    max-height: -webkit-calc(100vh - (150px));
    max-height: calc(100vh - (100px));
    height: calc(100vh + 200px);
}
</style>